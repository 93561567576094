import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { useExtension } from "../hooks/useExtension";
import { useMascot } from "../hooks/useMascot";

export default function MascotExtensionBar() {
  const { sendMessageExtension } = useExtension();
  const [mascots, setMascots] = useState([]);
  const { mascot } = useMascot();
  const history = useHistory();

  useEffect(() => {
    sendMessageExtension("getInstalledMascot").then((res) => {
      setMascots(res);
    });
    // eslint-disable-next-line
  }, []);

  const selectMascot = (m) => {
    history.push(`/mascot/${m}/chat`);
  };

  const removeMascot = async (m) => {
    let data = await sendMessageExtension("removeInstalledMascot", m);
    setMascots(data);
  };

  const toggleCursor = async (m) => {
    await sendMessageExtension("toggleCursor");
  };

  return (
    <div className="mascot-extension-bar">
      <select className="small" value={mascot._id} onChange={(e) => selectMascot(e.target.value)}>
        {mascots.map((m) => (
          <option key={m} value={m._id}>
            {m.name}
          </option>
        ))}
      </select>

      <div className="btn-bar" style={{ display: "flex", flexDirection: "row" }}>
        <button className="small" onClick={() => removeMascot(mascot._id)}>
          Remove Mascot
        </button>
        <button className="small" onClick={() => toggleCursor()}>
          Toggle Cursor
        </button>
      </div>
    </div>
  );
}
